import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useObjectMemo } from "../../../hooks";

import { type OnboardingPlatform } from "./types";
import { actions } from "./slice";
import { useFetchOnboardingPrompts } from "./requests";
import { selectOnboardingData } from "./selectors";

import type { RootState } from "../../entities/store";

export const useOnboardingContainer = (platform: OnboardingPlatform) => {
  const { fetchOnboardingPrompts: fetchOnboardingPromptsRequest } =
    useFetchOnboardingPrompts();
  const dispatch = useDispatch();
  const { title, description, prompts } = useSelector((state: RootState) =>
    selectOnboardingData(state),
  );

  const fetchOnboardingPrompts = useCallback(() => {
    fetchOnboardingPromptsRequest({
      onData: (apiResponse) =>
        dispatch(actions.upsert({ ...apiResponse, platform })),
    });
  }, [fetchOnboardingPromptsRequest, dispatch, platform]);

  return useObjectMemo({
    title,
    description,
    prompts,
    fetchOnboardingPrompts,
  });
};
