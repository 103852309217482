import React, { useState, useContext } from "react";
import { useLocalization } from "@fluent/react";
import { useFeature } from "byzantine";
import cc from "classcat";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { Dialog, formatNumber, Button } from "cerulean";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";
import { useCurrentUser } from "../contexts/CurrentUserContext";

const hasLimitType = (limits, transactionType) =>
  // this determines whether a segment or sub-user limit exists for the transaction type
  !!(limits[transactionType] || limits[`organization_user_${transactionType}`]);

export const RenderLimit = ({
  limits,
  transactionType,
  useParagraphTag = true,
}) => {
  const { l10n } = useLocalization();
  if (!limits[transactionType]) return null;
  const periods = limits[transactionType];

  return Object.entries(periods).map(([period, limit]) => {
    if(!limit || !limit.amount || !limit.amount_available){
      return null;
    }

    // only display "<amount_available> left" if amount_available differs from amount
    const limitsFluentVars = {
      period: Number(period),
      amount: formatNumber(limit.amount),
      amountLeft: formatNumber(limit.amount_available),
    };
    const content =
      limit.amount !== limit.amount_available
        ? l10n.getString("transfer-limit-amount-left", limitsFluentVars)
        : l10n.getString("transfer-limit-amount", limitsFluentVars);

    return useParagraphTag ? (
      <p className="margin--none" key={`${transactionType}-${period}-p`}>
        {content}
      </p>
    ) : (
      content
    );
  });
};
RenderLimit.propTypes = {
  limits: PropTypes.object,
  transactionType: PropTypes.string,
  useParagraphTag: PropTypes.bool,
};

const LimitsDialog = ({ isWire = false, limitsForDisplay = {} }) => {
  const { l10n } = useLocalization();
  const { currentUser } = useCurrentUser();
  const isBusiness = currentUser?.isBusiness();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { featureEnabled } = useFeature();
  const { transfer_disclosure, short_name, wire_info, wire_disclosure, instant_transfer_disclosure } =
    useContext(InstitutionSettingsContext);

  const showLimitsSection = Object.keys(limitsForDisplay).length > 0;
  const showDisclosureSection = !!(isWire
    ? wire_info || wire_disclosure
    : transfer_disclosure || instant_transfer_disclosure);
  const showInstant = featureEnabled("fednow_send") && featureEnabled("transfers_redesign");

  return (
    <>
      {(showLimitsSection || showDisclosureSection) && (
        <Button
          type="button"
          onClick={() => setIsDialogOpen(true)}
          label={
            isWire
              ? l10n.getString("view-wire-transfer-limits")
              : l10n.getString("view-transfer-limits")
          }
          kind="plain"
          size="s"
        />
      )}
      <Dialog
        isOpen={isDialogOpen}
        onUserDismiss={() => setIsDialogOpen(false)}
        title={l10n.getString("title-limits-and-disclosures")}
      >
        <div>
          <div className="fontColor--secondary">
            {hasLimitType(limitsForDisplay, "ach_push") && (
              <>
                <div
                  className="margin--top--s fontWeight--semibold fontColor--heading"
                  style={{ marginBottom: "6px" }}
                >
                  {l10n.getString("limit-external-push-title")}
                </div>
                <p>
                  {l10n.getString("limit-external-push-details", {
                    accountName: short_name,
                  })}
                </p>
                {isBusiness && limitsForDisplay?.organization_user_ach_push && (
                  <>
                    <div className="fontColor--heading">
                      {l10n.getString("limit-personal-title")}
                    </div>
                    <RenderLimit
                      limits={limitsForDisplay}
                      transactionType="organization_user_ach_push"
                    />
                    <br />
                  </>
                )}
                {isBusiness && limitsForDisplay?.ach_push && (
                  <div className="fontColor--heading">
                    {l10n.getString("limit-organizational-title")}
                  </div>
                )}
                {limitsForDisplay?.ach_push && (
                  <>
                    <RenderLimit
                      limits={limitsForDisplay}
                      transactionType="ach_push"
                    />
                  </>
                )}
              </>
            )}
            {limitsForDisplay?.ach_pull && (
              <>
                <div
                  className={cc([
                    "fontWeight--semibold fontColor--heading",
                    {
                      "margin--top--xl": hasLimitType(
                        limitsForDisplay,
                        "ach_push",
                      ),
                      "margin--top--s": !hasLimitType(
                        limitsForDisplay,
                        "ach_push",
                      ),
                    },
                  ])}
                  style={{ marginBottom: "6px" }}
                >
                  {l10n.getString("limit-external-pull-title")}
                </div>
                <p>
                  {l10n.getString("limit-external-pull-details", {
                    accountName: short_name,
                  })}
                </p>
                {isBusiness && (
                  <div className="fontColor--heading">
                    {l10n.getString("limit-organizational-title")}
                  </div>
                )}
                <RenderLimit
                  limits={limitsForDisplay}
                  transactionType="ach_pull"
                />
                <br />
              </>
            )}
            {limitsForDisplay?.immediate_ach_pull && (
              <p>
                {l10n.getString("funds-available-from-external-pull", {
                  accountName: short_name,
                  amount: RenderLimit({
                    limits: limitsForDisplay,
                    transactionType: "immediate_ach_pull",
                    useParagraphTag: false,
                  })[0],
                })}
              </p>
            )}
            {hasLimitType(limitsForDisplay, "wire") && isWire && (
              <>
                <div
                  className="margin--top--s fontWeight--semibold fontColor--heading"
                  style={{ marginBottom: "6px" }}
                >
                  {l10n.getString("limit-external-transfer-title")}
                </div>
                <p>
                  {l10n.getString("limit-external-transfer-details", {
                    accountName: short_name,
                  })}
                </p>
                {isBusiness && limitsForDisplay?.organization_user_wire && (
                  <>
                    <div className="fontColor--heading">
                      {l10n.getString("limit-personal-title")}
                    </div>
                    <RenderLimit
                      limits={limitsForDisplay}
                      transactionType="organization_user_wire"
                    />
                    <br />
                  </>
                )}
                {isBusiness && limitsForDisplay?.wire && (
                  <div className="fontColor--heading">
                    {l10n.getString("limit-organizational-title")}
                  </div>
                )}
                {limitsForDisplay?.wire && (
                  <RenderLimit
                    limits={limitsForDisplay}
                    transactionType="wire"
                  />
                )}
              </>
            )}
            {showInstant && hasLimitType(limitsForDisplay, "fednow_send") && !isWire && (
              <>
                <div
                  className="margin--top--s fontWeight--semibold fontColor--heading"
                  style={{ marginBottom: "6px" }}
                >
                  {l10n.getString("limit-fednow-send-title")}
                </div>
                <p>
                  {l10n.getString("limit-fednow-send-details", {
                    accountName: short_name,
                  })}
                </p>
                {limitsForDisplay?.fednow_send && (
                  <RenderLimit
                    limits={limitsForDisplay}
                    transactionType="fednow_send"
                  />
                )}
              </>
            )}
          </div>
          {showDisclosureSection && (
            <div
              className={cc([
                "fontColor--heading fontWeight--semibold",
                {
                  "margin--top--xl": showLimitsSection,
                  "margin--top--s": !showLimitsSection,
                },
              ])}
              style={{ marginBottom: "6px" }}
            >
              {l10n.getString("limit-disclosures-title")}
            </div>
          )}
          {!isWire && (
            <div className="fontColor--secondary">
              <ReactMarkdown linkTarget="_blank">
                {transfer_disclosure}
              </ReactMarkdown>
            </div>
          )}
          {isWire && (
            <>
              <div
                className="fontColor--secondary"
                style={{
                  marginBottom: "6px",
                }}
              >
                <ReactMarkdown linkTarget="_blank">{wire_info}</ReactMarkdown>
                <ReactMarkdown linkTarget="_blank">
                  {wire_disclosure}
                </ReactMarkdown>
              </div>
            </>
          )}
          {showInstant && !isWire && (
            <div className="fontColor--secondary padding--y">
              <ReactMarkdown linkTarget="_blank">
                {instant_transfer_disclosure}
              </ReactMarkdown>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

LimitsDialog.propTypes = {
  limitsForDisplay: PropTypes.object,
  isWire: PropTypes.bool,
};

export default LimitsDialog;
