import { OnboardingPromptStatus } from "./types";

import type { RootState } from "../../entities";

export const selectOnboardingData = (state: RootState) => {
  return state.onboarding;
};

export const selectAllOnboardingItemsComplete = (state: RootState) => {
  return (
    state.onboarding.prompts.length > 0 &&
    state.onboarding.prompts.every(
      ({ status }) => status === OnboardingPromptStatus.completed,
    )
  );
};
